<template>
  <public-layout>
    <div class="container mx-auto">
      <div class="flex flex-col justify-center px-4">
        <h1 class="text-2xl font-bold mb-4 text-center">
          POLÍTICA DE PRIVACIDAD
        </h1>
        <section class="">
          <div class="mb-8">
            A fin de dar cumplimiento con lo establecido en la Ley Federal de Protección de Datos Personales en Posesión
            de los Particulares, su Reglamento y Lineamientos aplicables (la "Ley"),
            <strong>GO INNOVATIONS, S.A.P.I.  de C.V.</strong>
            sus filiales y/o subsidiarias, y/o sus partes relacionadas ("SAMII"), con domicilio en José J. Gamboa #200
            en la colonia Colinas de San Jerónimo en Monterrey, Nuevo León CP: 64630 (el "Domicilio"), Página Web
            <a class="text-purple-400" @click="redirect('http://www.samiiweb.com/')">www.samiiweb.com</a> (el "Sitio") y
            demás Plataformas presentes y futuras de su propiedad, y correo electrónico
            <a class="text-purple-400" href="mailto:info@Samiiweb.com">info@Samiiweb.com</a> (el "Correo Electrónico"),
            pone a su disposición el presente:
          </div>
          <div class="mb-8 text-center">
            <strong>AVISO DE PRIVACIDAD,</strong>
          </div>
          <div class="mb-8">
            Con la finalidad de dar un tratamiento legítimo, controlado e informado a sus datos personales, que
            actualmente nos proporcione o en el futuro obren en nuestras bases de datos, o que  hayan sido recopilados
            por cookies, o cualquier otra tecnología de seguimiento web, así como,  garantizar su privacidad y su
            derecho a la autodeterminación informativa al proporcionarnos dichos datos, siendo SAMII responsable del uso
            y protección de sus datos personales los cuales serán tratados con base en los principios de licitud,
            consentimiento, información, calidad, finalidad, lealtad, proporcionalidad y responsabilidad previstos en la
            Ley.
          </div>
          <div class="mb-8">
            <strong>UTILIZACIÓN DE LA INFORMACIÓN.</strong>
            <div>
              La información que Usted nos provea a través del acceso, registro y creación de perfil de Usuario en el
              Sitio y/o correo electrónico, y/o llenado de formularios o encuestas físicas o electrónicas, en tiempo
              real o histórico, se procesará y ordenará, para que genere indicadores de datos, mismos que SAMII podrá
              usar para tomar decisiones pertinentes a su negocio, bajo su propio análisis y responsabilidad. Toda la
              información que sea recopilada se utilizará con fines estadísticos de manera genérica y no personalizada,
              asociados con el crecimiento, mantenimiento y administración de SAMII respetando en todo momento su
              privacidad. Estos usos incluyen nuestras operaciones y administración internas, la comunicación con Usted
              y el cumplimiento de las solicitudes de servicios y/o productos provistos por SAMII, así como para
              mejorar, desarrollar, perfeccionar y, proporcionar los servicios de SAMII, a través de sus partes
              relacionadas, filiales, o proveedores autorizados y/o socios comerciales, estableciendo las medidas
              adecuadas a fin de limitar el uso de la información recabada de usted, únicamente para fines legales y
              autorizados en conformidad con este Aviso, así como con las debidas medidas de confidencialidad y
              seguridad.
            </div>
          </div>
          <div class="mb-8">
            SAMII también podrá recabar su dirección de IP (Internet Protocol) para ayudar a diagnosticar problemas con
            el servidor de SAMII y para administrar el Sitio. Una dirección de IP es un número que se le asigna a su
            computadora cuando usa Internet. Su dirección de IP también es utilizada para ayudar a identificarle dentro
            de una sesión particular y para recolectar información demográfica general. SAMII podrá hacer uso de
            tecnología "push" a través de la aplicación que SAMII usa para enviar notificaciones con su autorización
            previa, este medio de comunicación no tiene ningún tipo de acceso a otras funciones o información del equipo
            con el que se conecta al Sitio. La información puede incluir la URL de la que provienen (estén o no en el
            Sitio), a qué URL acceden seguidamente (estén o no en el Sitio), qué navegador están usando, así como
            también las páginas visitadas, las búsquedas realizadas, las publicaciones, preferencias comerciales,
            mensajes, etc.
          </div>
          <div class="mb-8">
            <strong>USO DE COOKIES.</strong>
            <div>
              SAMII le informa que, mediante el uso de cookies y tecnologías similares, busca garantizar la mejor
              experiencia posible en el Sitio al proporcionarle información personalizada, recordando sus preferencias
              de servicios y de mercadeo y ayudándolo a obtener la información adecuada. En caso de que requiera mayor
              información respecto al uso de cookies y tecnologías similares, SAMII pone a su disposición la Política de
              uso de Cookies.
            </div>
          </div>
          <div class="mb-8">
            <strong>1.	DATOS PERSONALES SOLICITADOS</strong>
            <div>
              SAMII, y/o las empresas controladoras de ésta y/o empresas filiales y/o subsidiarias y/o partes
              relacionadas y/o aquellos terceros que, por la naturaleza de su trabajo o funciones tengan la necesidad de
              tratar y/o utilizar sus datos personales ("Terceros relacionados"), solicita y obtiene datos personales en
              general, así como datos personales considerados sensibles por la Ley (en lo sucesivo "Datos Personales
              Generales" y "Datos Personales Sensibles", respectivamente; y de manera conjunta referidos como los "Datos
              Personales") de las personas en adelante descritas.
            </div>
          </div>
          <div class="mb-8">
            Los Datos Personales Sensibles podrán ser solicitados por medios electrónicos o físicos, en el entendido de
            que toda información proporcionada en físico, será considerada y tratada como si se hubiera proporcionado y
            autorizado en el Sitio, y por lo cual se regirá por el presente documento.
          </div>
          <div class="mb-8">
            En todos los casos, la recolección de Datos Personales por parte de SAMII es realizada de buena fe y para
            los fines aquí expuestos; por tal motivo, se presume que los datos proporcionados por sus titulares son
            apegados a la verdad y completos, por lo que son responsabilidad del titular que los proporciona.
          </div>
          <div class="mb-8">
            <ul>
              <li class="pl-8 w-full flex flex-row">
                A)
                <div style="min-width: 25px;" />
                <label>
                  <strong>EMPLEADOS Y CANDIDATOS.</strong> Los Datos Personales que serán recabados de empleados y
                  candidatos son necesarios para documentar la relación laboral que existe o podrá existir con cada uno
                  de ellos. Los Datos Personales que Usted proporcionará voluntaria y libremente a SAMII, constan de
                  información personal que es incluida o podrá ser incluida en contratos, cartas, formatos, listados,
                  bases de datos u otros medios físicos y/o electrónicos, según corresponda, a efecto de que SAMII pueda
                  documentar su relación con la compañía, el proceso de selección y reclutamiento que haya tenido o vaya
                  a tener, pueda llevar un registro adecuado de la relación laboral con sus empleados y cumplir con las
                  políticas internas, procedimientos y demás obligaciones legales aplicables a la empresa.
                  <br />
                  Los Datos Personales Generales que le serán solicitados son los siguientes: nombre completo; número de
                  teléfono particular y/o móvil; correo electrónico; Clave de Registro Federal de Contribuyentes (RFC) y
                  copia de Cédula de Identificación Fiscal; Opinión del cumplimiento de obligaciones fiscales; Clave Única
                  de Registro de Población (CURP) y copia de constancia; copia de identificación oficial con fotografía
                  (credencial para votar o pasaporte); edad; lugar y fecha de nacimiento; copia de acta de nacimiento;
                  domicilio y copia de comprobante de domicilio; copia de cartilla militar liberada (en caso de ser
                  aplicable); estado civil y copia del acta de matrimonio (en caso de ser aplicable); nacionalidad y copia
                  de documento migratorio (en caso de ser aplicable); número de seguridad social y copia de credencial del
                  Instituto Mexicano del Seguro Social (“IMSS”); profesión y ocupación actual; certificados de educación o
                  constancias de estudios; certificados o constancias de dominio de idiomas; número de empleado; categoría
                  de empleado y puesto; sueldo, percepciones y deducciones; copia de la constancia de alta, baja o
                  modificación de sueldo del IMSS; copia de la constancia de percepciones del último empleo; fotografías;
                  datos de familiares directos y referencias personales y/o laborales; datos de contactos en caso de
                  emergencia; información relacionada con beneficiarios o dependientes económicos, la cual podrá incluir
                  nombre completo, fecha de nacimiento y otros datos de identificación, tales como copias de las actas de
                  nacimiento de dependientes económicos; currículum vitae; información respecto al desempeño del empleado;
                  constancias de empleos anteriores; copia de comprobante de afiliación a AFORE; copia de constancias de
                  créditos hipotecarios (INFONAVIT); cartas de recomendación y/o cualquier otra documentación o
                  información que se relacione con la relación laboral que exista o llegara a existir entre SAMII y Usted
                  como trabajador de la misma.
                </label>
              </li>
              <li class="pl-8 w-full flex flex-row">
                B)
                <div style="min-width: 25px;" />
                <label>
                  <strong>PROVEEDORES Y CLIENTES.</strong> Los Datos Personales que serán recabados de proveedores y
                  clientes de SAMII, son necesarios para documentar la relación jurídica que existe con cada uno de
                  ellos. Los Datos Personales que Usted proporcionará voluntaria y libremente a SAMII, constan de
                  información personal que es incluida o podrá ser incluida en la Base de Datos de SAMII, según
                  corresponda, a efecto de que SAMII pueda llevar un registro adecuado de su relación con sus
                  proveedores y clientes y cumplir con las políticas internas, procedimientos y demás obligaciones
                  legales aplicables a la empresa.
                  <br />
                  Los Datos Personales Generales que le serán solicitados como personas físicas o representantes o
                  apoderados de la Sociedad relacionada con SAMII son los siguientes: nombre completo, nacionalidad, edad,
                  lugar y fecha de nacimiento, domicilio, número de teléfono particular y/o móvil, correo electrónico,
                  estado civil, profesión, Clave de Registro Federal de Contribuyentes (RFC), Clave Única de Registro de
                  Población (CURP) principalmente. En caso de que los Proveedores y/o Clientes sean personas morales, los
                  Datos Personales Generales que le serán solicitados son, denominación social, nombre comercial,
                  domicilio social, nacionalidad social, datos de constitución de la Empresa y todos los cambios o
                  reformas realizadas a la misma, datos del acta donde conste el otorgamiento de poderes de los
                  representantes o apoderados legales, información financiera básica, y todos aquellos que considere
                  necesarios para su relación comercial.
                </label>
              </li>
            </ul>
          </div>
          <div class="mb-8">
            <strong>2.  FINALIDADES DEL TRATAMIENTO DE LOS DATOS PERSONALES</strong>
            <div>
              Los Datos Personales proporcionados a SAMII serán utilizados según se explica en adelante. En todos los
              casos, una vez cumplidas las finalidades del tratamiento de sus Datos Personales, y cuando no exista
              disposición legal que establezca lo contrario, SAMII procederá a la cancelación, eliminación y/o
              destrucción de los Datos Personales recibidos, en los términos establecidos por la Ley.
            </div>
          </div>
          <div class="mb-8">
            <ul>
              <li class="pl-8 w-full flex flex-row">
                A)
                <div style="min-width: 25px;" />
                <label>
                  <strong>TRABAJADORES.</strong> Finalidades que son necesarias para la existencia, mantenimiento y
                  cumplimiento de su relación laboral con SAMII que de manera enunciativa más no limitativa se citan las
                  siguientes: (i) cumplir con las obligaciones derivadas de la relación laboral con usted; (ii) llevar a
                  cabo una adecuada administración del personal; (iii) formar expedientes de empleados; (iv) formar
                  expedientes médicos; (v) pago y administración de nómina; (vi) pago de beneficios, salarios y
                  prestaciones, bonos, reembolsos, pensiones, seguros y otros; (vii) contratación de seguros; (viii)
                  documentar y controlar la asignación de equipos de cómputo y comunicaciones; (ix) auditorías internas;
                  (x) creación de cuenta de correo electrónico laboral; (xi) elaboración de directorio de la empresa;
                  (xii) asignar herramientas de trabajo; (xiii) asignar claves y contraseñas; (xiv) asegurar el
                  cumplimiento con obligaciones de confidencialidad y otras obligaciones laborales; (xv) verificar
                  referencias personales y laborales; (xvi) contactar a sus familiares, dependientes económicos y/o
                  beneficiarios en caso de emergencia.
                </label>
              </li>
              <li class="pl-8 w-full flex flex-row">
                B)
                <div style="min-width: 25px;" />
                <label>
                  <strong>CANDIDATOS.</strong> Finalidades que dan origen y son necesarias para el proceso de selección y
                  reclutamiento laboral de  SAMII que de manera enunciativa más no limitativa se citan las siguientes:
                  (i) dar trámite a su solicitud de empleo; (ii) consultar la información en fuentes de acceso público;
                  (iii) verificar la veracidad de la información proporcionada; (iv) hacer investigaciones laborales;
                  (v) realizar investigaciones socioeconómicas; (vi) identificar, ubicar, contactar e informar sobre el
                  desarrollo del proceso de selección y reclutamiento; (vii) elaborar propuesta económica, en su caso;
                  (viii) formar su expediente laboral, en caso de ser contratado; y (ix) mantener la seguridad de
                  nuestras instalaciones durante el proceso de entrevistas.
                </label>
              </li>
              <li class="pl-8 w-full flex flex-row">
                C)
                <div style="min-width: 25px;" />
                <label>
                  <strong>CLIENTES Y PROVEEDORES.</strong> Finalidades que son necesarias para la existencia,
                  mantenimiento y cumplimiento de su relación jurídica de proveeduría con SAMII: (i) dar de alta u
                  otorgar la información necesaria como proveedor o cliente autorizado de SAMII; (ii) verificar la
                  veracidad de la información proporcionada; (iii) mantener la seguridad de nuestras instalaciones;
                  (iv)conocer las necesidades de servicios que puedan requerir o proveer, (v) la naturaleza y
                  funcionalidades de los productos que provee o puede necesitar, (vi)  la identificación, operación,
                  administración y comercialización de los servicios contratados o de los productos adquiridos, y (vii)
                  todo lo que se desprenda de la relación comercial presente o futura que llegaren a solidificar entre
                  el Proveedor y/o el Cliente y SAMII.
                </label>
              </li>
            </ul>
          </div>
          <div class="mb-8">
            En todos los casos, una vez cumplidas las finalidades del tratamiento de sus Datos Personales, y cuando no
            exista disposición legal que establezca lo contrario, SAMII procederá a la cancelación, eliminación y/o
            destrucción de los Datos Personales recibidos, en los términos establecidos por la Ley.
          </div>
          <div class="mb-8">
            <strong>3.	TRANSFERENCIA DE LA INFORMACIÓN.</strong>
            <div>
              Los datos a que se refiere este Aviso podrán ser transferidos a: (i) Terceros relacionados y/o aliados
              comerciales, con la finalidad de engrandecer la propuesta de negocio de SAMII, así como ofrecerle en base
              a sus necesidades  otros productos y servicios; (ii) algún tercero con el que SAMII, y/o Terceros
              relacionados y/o Aliados Comerciales hayan celebrado un contrato con la finalidad de ofrecer y promover
              productos y/o servicios de dicho tercero a través del Sitio; (iii) a autoridades judiciales, mexicanas y
              extranjeras, con la finalidad de dar cumplimiento a la ley, notificaciones, requerimientos u oficios de
              carácter judicial; (iv)a proveedores de servicios de internet sobre la cual esté montada la
              infraestructura tecnológica de SAMII.
              <br />
              En caso de realizar alguna transferencia de sus datos, en los que se requiera su consentimiento expreso,
              se lo informaremos a efecto de recabar el mismo.
            </div>
          </div>
          <div class="mb-8">
            <label>
              <strong>TRANSFERENCIAS DE LOS DATOS PERSONALES.</strong> Como parte de las operaciones propias del negocio
              y a fin de cumplir con las finalidades descritas, SAMII podrá compartir con terceros, nacionales o
              extranjeros, algunos o todos sus Datos Personales, de conformidad con lo siguiente:
            </label>
            <ul class="mb-4">
              <li class="mt-4 pl-8 w-full flex flex-row">
                A)
                <div style="min-width: 25px;" />
                <label>
                  <strong>TRABAJADORES Y CANDIDATOS.</strong> Estos terceros podrán ser empresas matrices, partes
                  relacionadas, filiales y/o subsidiarias de SAMII; autoridades; instituciones bancarias; asesores
                  profesionales externos; u otros prestadores de servicios que ofrezcan administración de nómina,
                  capacitación y entrenamiento de personal; servicios de salud, diagnóstico o tratamiento médico;
                  administración, capacitación o formación de recursos humanos; provisión de seguros y otros beneficios;
                  planeación de costos; soporte técnico y de tecnologías de la información y, en general, cualquier
                  tercero que actúe como encargado a nombre y por cuenta de SAMII.
                  <br />
                  De igual manera, SAMII podrá compartir con terceros, nacionales o extranjeros, algunos o todos sus
                  Datos Personales cuando éstos sean solicitados como referencias laborales y/o personales para la
                  tramitación de todo tipo de créditos, arrendamientos, compra-venta de artículos y/o partes que
                  componen los equipos de comunicación, inmuebles y/o cualquier otra actividad comercial que Usted
                  realice, así como para referencias laborales para futuras contrataciones. Asimismo, SAMII podrá
                  transferir sus Datos Personales a terceros, nacionales o extranjeros, cuando dicha comunicación de
                  datos esté prevista en una ley o Tratado en los que México sea parte, o bien, cuando sea requerido por
                  orden judicial o resolución de autoridad competente.
                  <br />
                  Estos terceros podrán ser autoridades, empresa matriz, subsidiarias, filiales y/o empresas que presten
                  servicios a SAMII, a fin de cumplir con obligaciones legales existentes; cumplir con una orden legal o
                  judicial; y mientras que sea necesario para la operación y funcionamiento ordinario de SAMII conforme
                  a sus políticas y procedimientos internos de selección y reclutamiento. Los Datos Personales también
                  podrán ser transferidos por SAMII a terceros que presten servicios de aplicación de exámenes médicos a
                  la empresa, a fin de gestionar y administrar estos servicios a nombre de y en beneficio de Usted como
                  titular de los datos para fines derivados de su posible contratación como empleado de la compañía.
                </label>
              </li>
              <li class="mt-4 pl-8 w-full flex flex-row">
                B)
                <div style="min-width: 25px;" />
                <label>
                  <strong>PROVEEDORES Y/O CLIENTES.</strong> Estos terceros podrán ser autoridades, empresa matriz,
                  partes relacionadas, subsidiarias, filiales y/o empresas que presten servicios a SAMII, a fin de
                  cumplir con obligaciones legales existentes; cumplir con una orden legal o judicial; y mientras que
                  sea necesario para la operación y funcionamiento ordinario de SAMII conforme a sus políticas y
                  procedimientos internos con proveedores y/o clientes de SAMII.
                </label>
              </li>
            </ul>
            En todos los casos, SAMII comunicará el presente Aviso de Privacidad a estos terceros y se asegurará a
            través de la firma de convenios y/o la adopción de otros documentos vinculantes, que dichos terceros
            mantengan las medidas de seguridad administrativas, técnicas y físicas necesarias para resguardar sus Datos
            Personales, así como que dichos terceros únicamente utilicen sus Datos Personales para las finalidades para
            los cuales fueron recabados.
            <br />
            Asimismo, tanto SAMII, como responsable de recabar los Datos Personales, así como cualquier otra persona
            relacionada con SAMII que tenga acceso a la información contenida en este Aviso de Privacidad, quedarán
            obligados a resguardarla bajo las mismas normas de seguridad y confidencialidad, y a no revelarla ni hacer
            mal uso de la misma, o en caso contrario serán responsables de conformidad con las leyes aplicables.
            <br />
            No obstante, lo anterior, SAMII no transferirá sus Datos Personales a terceros no relacionados con SAMII,
            salvo en los casos antes citados y los previstos en la Ley, sin su consentimiento previo.
          </div>
          <div class="mb-8">
            <strong>4. 	MEDIOS Y PROCEDIMIENTOS PARA EL EJERCICIO DE LOS DERECHOS ARCO</strong>
            <div>
              <div class="mb-4">
              Usted, como titular de los Datos Personales proporcionados a SAMII, podrá solicitar en cualquier momento,
              el ejercicio de sus derechos de acceso, rectificación, cancelación u oposición (los “Derechos ARCO”) al
              tratamiento de sus Datos Personales, consistentes en: (i) acceder a sus Datos Personales y a los detalles
              del tratamiento de los mismos; (ii) rectificar sus Datos Personales en caso de ser inexactos o incompletos
              ; (iii) cancelar sus Datos Personales cuando considere que no se requieren para alguna de las finalidades
              señalados en este Aviso de Privacidad, estén siendo utilizados para finalidades no consentidas o haya
              finalizado su relación contractual o de servicio u otra con SAMII; y (iv) oponerse al tratamiento de sus
              Datos Personales para fines específicos.
              </div>
              <div class="mb-4">
              Para tal fin, Usted deberá presentar su petición por escrito a SAMII ubicado en el Domicilio, o bien,
                enviar su petición al Correo Electrónico dirigido al encargado del Departamento de Datos Personales,
                según sea aplicable, la cual deberá contener como mínimo la siguiente información: (a) su nombre
                completo y domicilio, u otro medio idóneo para comunicarle la respuesta a su solicitud; (b) los
                documentos que acrediten su identidad o, en su caso, la de su representante legal; (c) la descripción
                clara y precisa de los Datos Personales respecto de los que se busca ejercer alguno de los derechos
                antes mencionados; y (d) cualquier otro elemento o información que facilite la localización de los Datos
                Personales, así como cualquier otro documento requerido por la regulación actual en el momento de
                presentar la solicitud. Usted también podrá solicitar al mencionado Correo Electrónico mayor información
                sobre el procedimiento para ejercer sus Derechos ARCOs.
              </div>
              <div>
                La respuesta a su solicitud le será dada a conocer por SAMII en los términos y plazos establecidos en la
                Ley. No obstante, Usted podrá obtener más información acerca del estado que guarda su solicitud y del
                plazo de respuesta de la misma, contactando o enviando su petición al Correo Electrónico, donde además
                se podrá atender cualquier aclaración o duda que pudiera tener respecto al tratamiento de sus Datos
                Personales y el ejercicio de sus Derechos ARCO.
              </div>
            </div>
          </div>
          <div class="mb-8">
            <strong>5.	 REVOCACIÓN DEL CONSENTIMIENTO; LIMITACIÓN DE USO Y DIVULGACIÓN DE LOS DATOS PERSONALES</strong>
            <div>
              <div class="mb-4">
                Usted también podrá revocar, en cualquier momento, el consentimiento que haya otorgado a SAMII para el
                tratamiento de sus Datos Personales, así como solicitar que se limite el uso y divulgación de los mismos
                , siempre y cuando no lo impida una disposición legal. Para tal fin, Usted deberá presentar su solicitud
                por escrito a SAMII ubicado en el Domicilio, o bien, enviar su solicitud al Correo Electrónico con
                atención al encargado del Departamento de Datos Personales, según sea aplicable. Dicha solicitud deberá
                cumplir con los mismos requisitos mencionados en la Sección 4. anterior.
              </div>
              <div class="mb-4">
                La respuesta a su solicitud le será dada a conocer por SAMII en los términos y plazos establecidos en la
                Ley. No obstante, Usted podrá obtener más información acerca del estado que guarda su solicitud y del
                plazo de respuesta de la misma, contactando al o enviando su petición al Correo Electrónico, donde
                además podrán atender cualquier aclaración o duda que pudiera tener respecto al tratamiento y estos
                derechos que le corresponden respecto a sus Datos Personales.
              </div>
              <div>
                En caso de que sus Datos Personales hubiesen sido remitidos con anterioridad a la fecha de revocación
                del consentimiento y sigan siendo tratados por encargados, SAMII hará del conocimiento de éstos últimos
                dicha revocación, para que procedan a efectuar lo conducente.
              </div>
            </div>
          </div>
          <div class="mb-8">
            <strong>6.	CAMBIOS AL AVISO DE PRIVACIDAD</strong>
            <div>
              SAMII se reserva el derecho de modificar y/o actualizar este Aviso de Privacidad en alguna o todas sus
              partes a su entera discreción, en cuyo caso lo comunicará aquí mismo a través de su Sitio; y según sea el
              caso particular de cada titular, a través de sus redes internas, o por medio de un aviso que se colocará
              en los medios habituales (físicos o electrónicos) de comunicación de SAMII y en un lugar visible del
              Domicilio, o mediante un aviso por escrito dirigido a su correo electrónico, según sea legalmente
              requerido.
            </div>
          </div>
          <div class="mb-8">
            <strong>7.	 FORMA DIGITAL, ELECTRÓNICA O EN LÍNEA</strong>
            <div>
              La Partes acuerdan que la forma para perfeccionar el acuerdo de voluntades entre ellas podrá ser el de
              formato Digital, Electrónico o en Línea, en donde bastará manifestar su voluntad por medio de su
              aceptación, así como proporcionar los datos personales, en el propio Sitio de SAMII sin requerir estampar
              la firma en documento alguno.
            </div>
          </div>
          <div>
            Fecha de primera emisión: 10/6/2022, 6:10:14 PM.
          </div>
          <div>
            Fecha de última modificación: 10/6/2022, 6:10:14 PM.
          </div>
        </section>
      </div>
    </div>
  </public-layout>
</template>
<script>
import PublicLayout from '@/components/layouts/PublicLayout';

export default {
  name: 'PrivacyPolicy',
  components: {
    PublicLayout,
  },
  methods: {
    redirect(url) {
      window.open(url, '_blank');
    },
  },
}
</script>
